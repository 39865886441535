const baseUrl = "https://be-spin-mtn.ydafrica.com";

export function setupRedirect() {
  const urlHashProp = window.location.hash;
  const telephone = atob(urlHashProp.substring(1));
  const phoneDisplay = document.getElementById("phone-no");
  const formattedNumber = telephone.startsWith("234") ? telephone : `+234${telephone.substring(1)}`;
  async function handleRedirect() {

    await fetch(`${baseUrl}/api/v1/auth-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ msisdn: checkNumber(telephone) }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status === 400) {
            phoneDisplay.textContent = `Welcome! Login to Spin Now.`;

          setTimeout(function () {
            // hideSpinner();
            // document.getElementById("submodal-body").innerHTML = data.msg;
            showModal("loginModal");
            // const subLink = document.getElementById("subLink");
            // subLink.onclick = (e) => {
            //   e.preventDefault();
            //   closeModal("subModal");
            //   showModal("loginModal");
            // };
          }, 6000);

          throw new Error(data.msg);
        }
        if (data.status === 500) {
            phoneDisplay.textContent = `Welcome! Login to Spin Now.`;

          setTimeout(function () {
            // hideSpinner();
            document.getElementById("modal-body").innerHTML = data.msg;
            showModal("myModal");
            return false;
          }, 6000);
          throw new Error(data.msg);
        }
        if (data.status === 200) {
          // hideSpinner();
          document.getElementById("modal-body").innerHTML = "Success";
          localStorage.setItem("x-auth-token", data.token);
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("userPhone", telephone);
          updateUIForLoggedInUser(telephone);
          console.log(telephone);

          setTimeout(function () {
            // showModal("myModal");
              window.location.href = 'play.html';
          }, 6000);
        }
      });
  }

  function checkNumber(msisdn) {
    if (msisdn.length > 12 && msisdn.length === 13) {
      msisdn = msisdn.slice(3);
      msisdn = `0${msisdn}`;
      return msisdn;
    }
    return msisdn;
  }

  function showModal(modalId) {
    const modal = document.getElementById(modalId);
    modal.showModal();
  }
  function closeModal(modalId) {
    const modal = document.getElementById(modalId);
    modal.close();
  }

  function updateUIForLoggedInUser(phoneNumber) {
    const navLink = document.getElementById("prize-nav");
    if (navLink) {
      navLink.textContent = "My Prizes";
      navLink.href = "/prizes-won.html";
    }

    if (phoneDisplay) {
        phoneDisplay.textContent = `HELLO ${formattedNumber}, YOU ARE LOGGED IN`;
    }

    const userInfo = document.getElementById("userNum");
    userInfo.textContent = formattedNumber;

    const profileIcon = document.querySelector(".profile-icon");
    if (profileIcon) {
      profileIcon.classList.remove("hidden");
    }

    const profile = document.querySelector(".profile");
    if (profile) {
      profile.classList.remove("hidden");
    }

    const loginButton = document.getElementById("login");
    const homeButton = document.getElementById("home-btn");
    if (loginButton) {
      loginButton.style.display = "none";
    }
    if (homeButton) {
      homeButton.textContent = "Let’s Spin";
      homeButton.onclick = () => {
        window.location.href = "play.html";
      };
    }
  }

  window.addEventListener("load", handleRedirect);
}
