import volumeOn from '../images/volume.svg'
import volumeOff from '../images/mute.svg'
import spinnerImg from '../images/spinner-white.svg'
export const BASE_URL = 'https://be-spin-mtn.ydafrica.com';
export function currencyFormat(num) {
    return '₦' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatDate(date, divider) {
    if (typeof date === 'string' || typeof date === 'number') {
        date = new Date(date);
    }
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    day = day >= 10 ? day : '0' + day;
    month = month >= 10 ? month : '0' + month;
    return `${day}${divider}${month}${divider}${year}`;
}

export function showSpinner(button) {
    const spinner = document.createElement('img');
    spinner.src = spinnerImg;
    spinner.classList.add('spinner');
    button.appendChild(spinner);
    button.disabled = true;
}

export function hideSpinner(button) {
    const spinner = button.querySelector('.spinner');
    if (spinner) {
        button.removeChild(spinner);
    }
    button.disabled = false;
}

export function showModal(modalId, message) {
    const modal = document.getElementById(modalId);
    document.getElementById('modal-body').textContent = message;
    modal.showModal();
}

export function closeModal(modalId) {
    const modal = document.getElementById(modalId);
    if (modal && typeof modal.close === 'function') {
        modal.close();
    } else {
        console.error('Modal not found or does not support close method');
    }
}

export function playSound(audio) {
    audio.loop = true;
    audio.play();
}

export function stopSound(audio) {
    audio.pause();
    audio.currentTime = 0;
}

export function toggleMute(audio, muteIcon) {
    audio.muted = !audio.muted;
    muteIcon.src = audio.muted ? volumeOff : volumeOn;
}