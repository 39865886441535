const baseUrl = 'https://be-spin-mtn.ydafrica.com';

async function getUserDetails(token) {
    try {
        const response = await fetch(`${baseUrl}/api/v1/auth-user`, {
            method: 'GET',
            headers: {
                'x-auth-token': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            console.error('Failed to fetch user details:', response.status, response.statusText);
            logoutUser();
            return null;
        }

        const data = await response.json();

        if (!data.msisdn) {
            console.error('No MSISDN in response');
            logoutUser();
            return null;
        }

        return data;
    } catch (error) {
        console.error('Error fetching user details:', error);
        logoutUser();
        return null;
    }
}

async function getMsisdn() {
    let token = localStorage.getItem('x-auth-token'); // Correctly retrieve token here
    if (!token || isTokenExpired(token)) {
        console.error('No token found or token expired, please log in.');
        logoutUser();
        return;
    }

    try {
        const response = await fetch(`${baseUrl}/api/v1/auth-user`, {
            method: 'GET',
            headers: {
                'x-auth-token': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            console.error('Failed to fetch MSISDN:', response.status, response.statusText);
            logoutUser();
            return null;
        }

        const userMsisdn = await response.json();

        if (!userMsisdn.msisdn) {
            console.error('No MSISDN in response');
            logoutUser();
            return null;
        }

        let msisdn = userMsisdn.msisdn;
        let phone = document.querySelector('#phone');
        if (phone) phone.value = msisdn;

        return msisdn;
    } catch (error) {
        console.error('Error fetching MSISDN:', error);
        logoutUser();
        return null;
    }
}

function logoutUser() {
    localStorage.removeItem('x-auth-token');
    localStorage.removeItem('isLoggedIn');
    window.location.href = '/';
    console.log('User logged out');
}

function isTokenExpired(token) {
    if (!token) return true;

    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const decodedToken = JSON.parse(jsonPayload);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
}

function checkAuthToken() {
    let token = localStorage.getItem('x-auth-token');
    if (!token || isTokenExpired(token)) {
        console.error('Token missing or expired');
        logoutUser();
    }
}

export { checkAuthToken, logoutUser, getUserDetails, getMsisdn };
