// src/js/prize.js

export function renderPrizeBox() {

        const descriptionBox = document.querySelector('.description-box.desktop');
        if (!descriptionBox) return;
        const originalContainer = descriptionBox.parentElement;

        document.querySelectorAll('.prize-box').forEach(box => {
            box.addEventListener('click', function() {
                // Remove active class from all prize boxes
                document.querySelectorAll('.prize-box').forEach(box => {
                    box.classList.remove('active');
                });
                // Add active class to the clicked prize box
                this.classList.add('active');
                // Update title and description
                const title = this.getAttribute('data-title');
                const description = this.getAttribute('data-description');
                document.getElementById('prize-title').innerText = title;
                document.getElementById('prize-description').innerText = description;
                descriptionBox.style.borderColor = '#FCEE21';

                if (window.innerWidth <= 767) {
                    this.after(descriptionBox);
                    descriptionBox.classList.add('mobile');
                    descriptionBox.style.display = 'block';
                } else {

                    originalContainer.appendChild(descriptionBox);
                    descriptionBox.classList.remove('mobile');
                    descriptionBox.style.display = 'block';
                }
            });
        });

        // Handle window resize event
        window.addEventListener('resize', function() {
            if (window.innerWidth > 767) {
                // Append back to original container on desktop
                originalContainer.appendChild(descriptionBox);
                descriptionBox.classList.remove('mobile');
                descriptionBox.style.display = 'block';
            }
        });

}
