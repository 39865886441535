import bgMusic from '../xmas.mp3';
import bgMusic2 from '../game-music.mp3';
import { setupLogin } from './login';
import { setupRedirect } from './redirect';
import { renderPrizeBox } from './prize';
import { setupLandingPageAnimations } from './landingPageAnimations';
import { setupHamburgerMenu } from './hamburgerMenu';
import { subscribeUser } from './subscribeUser';
import { getPricePoints } from './pricePoint';
import { logoutUser } from './authCheck';
import { toggleMute } from './utils';

const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';


if (!isLoggedIn) {
    setupRedirect();
}

function setFinalState() {
    const goToWheelButton = document.querySelector(".home-btn");
    const phoneNo = document.getElementById("phone-no");
    const headerText = document.querySelector(".header-text");
    const muteBtn = document.getElementById('mute');

    if (goToWheelButton && phoneNo && headerText && muteBtn) {
        if (isLoggedIn) {
            goToWheelButton.classList.remove("hidden");
            phoneNo.classList.remove("hidden");
            headerText.classList.remove("hidden");
            muteBtn.classList.remove('hidden');
            headerText.style.color = "white";
        }
    }
}

function shouldPlayXmasMusic() {
    const today = new Date();
    const month = today.getMonth();
    const day = today.getDate();

    // Xmas music should play from December 15th to January 5th
    const isDecember = (month === 11 && day >= 15);
    const isJanuary = (month === 0 && day <= 5);

    return isDecember || isJanuary;
}

window.addEventListener("DOMContentLoaded", async () => {
    if (!isLoggedIn) {
        document.getElementById('profile').style.display = 'none';
    }

    renderPrizeBox();
    setupLogin();
    setupLandingPageAnimations();
    setupHamburgerMenu();

    const backgroundMusic = document.getElementById('backgroundMusic');
    const muteBtn = document.getElementById('mute');

    if (backgroundMusic && muteBtn) {
        // Check if it's Christmas time and decide which music to play
        if (shouldPlayXmasMusic()) {
            backgroundMusic.src = bgMusic; // Play Christmas music
        } else {
            backgroundMusic.src = bgMusic2; // Play default music
        }
        
        backgroundMusic.play().catch(error => console.log("Error auto-playing music:", error));
        muteBtn.addEventListener('click', () => toggleMute(backgroundMusic, muteBtn));
    }

    const subscriptionButton = document.getElementById('subsPlan');
    if (subscriptionButton) {
        subscriptionButton.addEventListener('click', subscribeUser);
    }
    const pricePointButton = document.getElementById('nx-btn');
    if (pricePointButton) {
        pricePointButton.addEventListener('click', getPricePoints);
    }

    const logoutButton = document.getElementById('logout-btn');
    if (logoutButton) {
        logoutButton.addEventListener('click', logoutUser);
    }

    // Ensure the Go to Wheel button and other elements are shown when the user navigates to the home page
    setFinalState();
    window.addEventListener('popstate', setFinalState); // Handle browser back/forward buttons
});
