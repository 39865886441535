import stars from '../images/starsbg.svg';
import starsMobile from '../images/starsmobile.svg';

export function setupLandingPageAnimations() {
    const body = document.body;
    const wheelImg = document.querySelector(".wheel-img");
    const goToWheelButton = document.querySelector(".home-btn");
    const phoneNo = document.getElementById("phone-no");
    const headerText = document.querySelector(".header-text");
    const muteBtn = document.getElementById('mute');
    const logo = document.getElementById('logo');

    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

    // Check if the user is on mobile
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    if (!wheelImg) return;

    if (isLoggedIn) {
        // element should be in their final state if the user is logged in
        goToWheelButton.classList.remove("hidden");
        phoneNo.classList.remove("hidden");
        headerText.classList.remove("hidden");
        muteBtn.classList.remove('hidden');
        logo.classList.remove('hidden');
        headerText.style.color = "white";
        body.style.background = `url(${isMobile ? starsMobile : stars}) no-repeat center/contain, linear-gradient(180deg, #0D0D19 0%, #1E2046 31%, #3A3086 68.5%, #8E74E2 100%)`;
        return; // Skip animation if user is logged in
    }

    // Initial state setup for the animation
    goToWheelButton.classList.add("hidden");
    phoneNo.classList.add("hidden");


    // Animation sequence
    setTimeout(() => {
        const newText = document.createElement("p");
        wheelImg.style.transition = "transform 1s ease, top 1s ease";
        wheelImg.style.transform = "translateY(400px)";
        body.style.backgroundColor = "white";

        newText.textContent = "All you have to do";
        newText.classList.add("header-subtext");
        headerText.insertAdjacentElement("afterend", newText);

        setTimeout(() => {
            body.style.backgroundColor = "#332C77";
            newText.style.color = "white";
            newText.textContent = "is spin";

            setTimeout(() => {
                body.style.backgroundColor = "#191A37";
                wheelImg.style.transform = "translateY(400px) rotate(60deg)";
                newText.style.display = "none";

                setTimeout(() => {
                    wheelImg.style.transform = "rotate(60deg) translateY(0)";
                    body.style.background = `url(${isMobile ? starsMobile: stars}) no-repeat center/ contain, linear-gradient(180deg, #0D0D19 0%, #1E2046 31%, #3A3086 68.5%, #8E74E2 100%)`;
                    goToWheelButton.classList.remove("hidden");
                    muteBtn.classList.remove('hidden');
                    logo.classList.remove('hidden');
                    phoneNo.classList.remove("hidden");
                    headerText.classList.remove("hidden");
                    headerText.style.color = "white";
                }, 1000);
            }, 2000);
        }, 1000);
    }, 2000);
}
