import { closeModal, showModal } from "./utils";


export async function subscribeUser() {
  const radioChecked = document.querySelector('input[name="subsPlan"]:checked');

  if (!radioChecked) {
    showModal("myModal", "Please select a subscription plan.");
    return;
  }

  const selectedValue = radioChecked.value;

  // Navigate to SMS app with the corresponding keyword
  switch (selectedValue) {
    case "200":
      window.location.href = 'sms:205?body=SV'; // For ₦200 plan
      break;
    case "100":
      window.location.href = 'sms:205?body=SP'; // For ₦100 plan
      break;
    case "50":
      window.location.href = 'sms:205?body=SM'; // For ₦50 plan
      break;
    case "30":
      window.location.href = 'sms:205?body=SE'; // For ₦20 plan
      break;
    default:
      showModal("myModal", "Invalid subscription plan selected.");
  }
  closeModal("subPackageModal");
}


