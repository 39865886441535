const baseUrl = "https://be-spin-mtn.ydafrica.com";
let text = "";
export async function getPricePoints(e) {

    e.preventDefault();

    var selectedValue = document.querySelector('input[name="radio"]:checked').value;

    const userDetails = await getUserDetails();

    let msisdn = userDetails.msisdn;

    msisdn = msisdn.slice(3);

    msisdn = `0` + msisdn;



    await fetch(`${baseUrl}/api/v1/users/${msisdn}/price-point`, {

            method: 'POST',

            headers: {

                'x-auth-token': token,

                'Accept': 'application/json',

                'Content-Type': 'application/json'

            },

            body: JSON.stringify({ priceValue: Number(selectedValue) })

        }).then((data) => data.json())

        .then((data) => {

            if (data.status === 400) {
                text = data.response.msg;
                showModal("myModal", text);

            }

            const priceValueToken = data.pricepoint.doc.priceValue;

            localStorage.setItem('price-value-token', priceValueToken);

            if (localStorage.getItem('price-value-token')) {

                window.location.href = 'play.html';

            }

        })

}