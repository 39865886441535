import { showSpinner, hideSpinner } from "./utils";
const baseUrl = 'https://be-spin-mtn.ydafrica.com';

export function setupLogin() {
    const button = document.querySelector('#submit-btn');
    const telephone = document.querySelector("#telephone");

    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

    if (isLoggedIn) {
        updateUIForLoggedInUser(localStorage.getItem('userPhone'));
    } else {
        button.addEventListener('click', handleSubmit);
        telephone.addEventListener('input', handleInput);
    }
}

function handleInput() {
    const button = document.querySelector('#submit-btn');
    const telephone = document.querySelector("#telephone");

    if (telephone.value) {
        button.style.backgroundColor = "#7DA1D6"
        button.disabled = false;
    } else {
        button.style.backgroundColor = "#D9D9D9"
        button.disabled = true;
    }
}

async function handleSubmit(e) {
    e.preventDefault();
    const button = document.querySelector('#submit-btn');
    const telephone = document.querySelector("#telephone");

    if (!telephone.value) {
        showModal('myModal', 'Enter phone number to proceed');
        return;
    }

    try {
        showSpinner(button);
        const response = await fetch(`${baseUrl}/api/v1/auth-user`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ msisdn: formatPhoneNumber(telephone.value) })
        });
        const data = await response.json();

        if (data.status === 200) {
            closeModal('loginModal');
            showModal('myModal', 'Login successful!');
            localStorage.setItem('x-auth-token', data.token);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('userPhone', telephone.value);
            updateUIForLoggedInUser(telephone.value);
            setTimeout(() => {
                window.location.href = 'play.html';
            }, 1000);
        } else {
            handleResponse(data, 'myModal');
        }
    } catch (error) {
        console.error('Error during fetch:', error);
        showModal('myModal', 'Network error. Please try again.');
    } finally {
        hideSpinner(button);
    }
}


function handleResponse(data, modalId) {
    let msg = document.getElementById('modal-body');
    let subMsg = document.getElementById('submodal-body');

    if (data.status === 500) {
        closeModal('loginModal');
        msg.innerHTML = data.msg;
        showModal('myModal');
        return;
    }

    if (data.status === 400) {
        closeModal('loginModal');
        subMsg.innerHTML = data.msg;
        // Set up the link in the subModal to open the subscription modal when clicked
        const subLink = document.getElementById('subLink');
        subLink.onclick = (e) => {
            e.preventDefault();
            closeModal('subModal');
            showModal('subPackageModal');
        };
        showModal('subModal');
        return;
    }

}

function formatPhoneNumber(msisdn) {
    return msisdn.startsWith('+234') && msisdn.length === 14 ? msisdn.slice(4) : msisdn;
}



function showModal(modalId, message) {
    const modal = document.getElementById(modalId);
    document.getElementById('modal-body').textContent = message;
    modal.showModal();
}

function closeModal(modalId) {
    const modal = document.getElementById(modalId);
    if (modal && typeof modal.close === 'function') {
        modal.close();
    } else {
        console.error('Modal not found or does not support close method');
    }
}

function updateUIForLoggedInUser(phoneNumber) {
    const navLink = document.getElementById('prize-nav');
    if (navLink) {
        navLink.textContent = 'My Prizes';
        navLink.href = '/prizes-won.html';
    }
    const home = document.getElementById('home');
    if (home) {
        home.href = '/play.html';
    }

    const formattedNumber = phoneNumber.startsWith('+234') ? phoneNumber : `+234${phoneNumber.substring(1)}`;
    const phoneDisplay = document.getElementById('phone-no');
    if (phoneDisplay) {
        phoneDisplay.textContent = `HELLO ${formattedNumber}, YOU ARE LOGGED IN`;
    }

    const userInfo = document.getElementById('userNum');
    userInfo.textContent = formattedNumber;

    const profileIcon = document.querySelector('.profile-icon');
    if (profileIcon) {
        profileIcon.classList.remove('hidden');
    }

    const loginButton = document.getElementById('login');
    const homeButton = document.getElementById('home-btn');
    if (loginButton) {
        loginButton.style.display = 'none';
    }
    if (homeButton) {
        homeButton.textContent = 'Let’s Spin';
        homeButton.onclick = () => { window.location.href = 'play.html'; };
    }
}